@media (max-width: 62rem) {
  .cta-button {
    display: none;
  }
}

.cta-button {
  background-color: #ec6759;
  color: #fff;
  padding: 5px 20px 7px 20px;
  font-weight: 700;
  font-size: 14px;
  border-radius: 5px 5px 0 0;
  position: fixed;
  right: -5px;
  bottom: 30dvh;
  transform: rotate(-90deg);
  transform-origin: bottom right;
  transition: right 0.2s ease-out;
}

.cta-button:hover {
  right: -2px;
}
