@media only screen and (max-width: 400px) {
  .mobileName {
    width: 10ch;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.zigZagTop::before {
  background: linear-gradient(140deg, transparent 50%, #efeeef 50%),
    linear-gradient(220deg, transparent 50%, #efeeef 50%);
  background-position: left-bottom;
  background-repeat: repeat-x;
  background-size: 10px 45px;
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  width: 100%;
  height: 42px;
  bottom: 329px;
}

.zigZagBottom::after {
  background: linear-gradient(-140deg, #efeeef 50%, transparent 50%),
    linear-gradient(-220deg, #efeeef 50%, transparent 50%);
  background-position: left-bottom;
  background-repeat: repeat-x;
  background-size: 10px 45px;
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  width: 100%;
  height: 33px;
  bottom: -11px;
}
