body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.map-container {
  height: 350px;
  border-radius: 15px;
  border: 2px solid #E0E0E0;
}

.mapboxgl-ctrl-geocoder--input {
  border-radius: 40px !important;
}

.mapboxgl-ctrl {
  border-radius: 40px !important;
}

.suggestions- {
  font-size: 5px;
}

.mapbox-logo {
  display: none;
}

.mapboxgl-ctrl-logo {
  display: none !important;
}

.mapbox-improve-map {
  display: none;
}

.mapboxgl-ctrl-compass {
  display: none;
}

.markdown {
  a {
    color: #0070f3;
  }
}

@-moz-document url-prefix() {
  .blurFilterFallback {
    background-color: rgba(255, 255, 255, 0.85) !important;
  }
}
