.Cropper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #efefef;
}

.CropContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 70px;
}

.CropControls {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 50%;
  transform: translateX(-50%);
  height: 70px;
  display: flex;
  align-items: center;
}

/* .CropSlider {
  padding: 22px 0px;
} */
